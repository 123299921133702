import setupDeprecationWorkflow from 'ember-cli-deprecation-workflow';

setupDeprecationWorkflow({
  workflow: [
    { handler: 'silence', matchId: 'component-template-resolving' },
    { handler: 'silence', matchId: 'ember-data:deprecate-array-like' },
    { handler: 'silence', matchId: 'template-action' },
    { handler: 'silence', matchId: 'ember-data:deprecate-promise-proxies' },
    { handler: 'silence', matchId: 'ember-test-waiters-legacy-module-name' },
  ],
});
